/**
 * Site header
 */
.site-header {
    /* border-top: 5px solid $grey-color-dark; */
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-family: $website-title-font-family;
    font-size: 26px;
    font-weight: $website-title-font-weight;
    line-height: 56px;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    margin-left: -10px;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }

    img {
        border-radius: 5px;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            >svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 12px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
}


@include media-query($on-laptop) {

    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width: calc(50% - (#{$spacing-unit} / 2));
    }
}

@media screen and (min-width: $content-width) {
    .social-media-list {
        float: right;
    }
}


@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width: calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    >li {
        font-family: $headings-font-family;
        letter-spacing: 0.09em;
        margin-bottom: $spacing-unit;
    }

    h2 {
        margin-bottom: 0;
    }
}

.post-meta {
    font-family: $headings-font-family;
    font-size: $small-font-size;
    color: $grey-color;

    @include media-query($on-palm) {
        font-size: 15px;
    }
}

.post-link {
    display: block;
    font-size: 24px;
    font-weight: $base-font-weight;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    letter-spacing: 0.08em;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 30px;
    }

    @include media-query($on-palm) {
        font-size: 30px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    em,
    i {
        font-style: italic;
        line-height: inherit;
    }

    strong,
    b,
    dt {
        font-style: $bold-body-font-style;
        font-weight: $bold-body-font-weight;
        font-style: $bold-body-font-style;
        line-height: inherit;
    }

    small {
        font-size: 60%;
        line-height: inherit;
    }

    h1,
    h2,
    h3,
    #toctitle,
    .sidebarblock>.content>.title,
    h4,
    h5,
    h6 {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-style: normal;
        letter-spacing: 0.04em;
        color: $headings-color;
        text-rendering: optimizeLegibility;
        margin-top: 1em;
        margin-bottom: 0.5em;
        line-height: 1.2em;
    }

    h2 {
        font-weight: $headings-font-weight;
        font-size: 28px;
    }

    h3 {
        font-style: normal;
        font-weigth: $headings-font-weight;
        font-size: 22px;

    }

    h4 {
        font-size: 18px;
        font-weight: $headings-font-weight;
    }

    .stemblock {
        overflow-x: auto;
    }

    /**
     * Blockquotes
     */
    /* blockquote { */
    /* 	color: $grey-color; */
    /* 	border-left: 4px solid $grey-color-light; */
    /* 	padding-left: $spacing-unit / 2; */
    /* 	font-size: $base-font-size; */

    /* 	> :last-child { */
    /*         margin-bottom: 0; */
    /* 	} */
    /* } */

    /* Tables */

    table {
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        overflow-x: scroll;
    }

    table {
        background: white;
        margin-bottom: 1.25em;
        border: solid 1px #dedede;
    }

    table thead,
    table tfoot {
        font-family: $table-header-font-family;
        font-style: $table-header-font-style;
        font-weight: $table-header-font-weight;
        background: $table-header-background-color;
    }

    table thead tr th,
    table thead tr td,
    table tfoot tr th,
    table tfoot tr td {
        padding: 0.5em 0.625em 0.625em;
        font-size: $table-header-font-size;
        color: rgba(0, 0, 0, 0.8);
        text-align: left;
    }

    table tr th,
    table tr td {
        padding: 0.5625em 0.625em;
        font-size: $table-font-size;
        color: rgba(0, 0, 0, 0.8);
    }

    /* table tr.even, table tr.alt, table tr:nth-of-type(even) { background: #f8f8f7; } */
    table thead tr th,
    table tfoot tr th,
    table tbody tr td,
    table tr td,
    table tfoot tr td {
        display: table-cell;
        line-height: 1.6;
    }

    table.pyhltable {
        border-collapse: separate;
        border: 0;
        margin-bottom: 0;
        background: none;
    }

    table.pyhltable td {
        vertical-align: top;
        padding-top: 0;
        padding-bottom: 0;
    }

    table.pyhltable td.code {
        padding-left: .75em;
        padding-right: 0;
    }

    pre.pygments .lineno,
    table.pyhltable td:not(.code) {
        color: #999;
        padding-left: 0;
        padding-right: .5em;
        border-right: 1px solid #ddddd8;
    }

    table.pyhltable .linenodiv {
        background: none !important;
        padding-right: 0 !important;
    }

    table.tableblock {
        max-width: 100%;
        border-collapse: separate;
    }

    table.tableblock td>.paragraph:last-child p>p:last-child,
    table.tableblock th>p:last-child,
    table.tableblock td>p:last-child {
        margin-bottom: 0;
    }

    table.spread {
        width: 100%;
    }

    th.halign-left,
    td.halign-left {
        text-align: left;
    }

    th.halign-right,
    td.halign-right {
        text-align: right;
    }

    th.halign-center,
    td.halign-center {
        text-align: center;
    }

    th.valign-top,
    td.valign-top {
        vertical-align: top;
    }

    th.valign-bottom,
    td.valign-bottom {
        vertical-align: bottom;
    }

    th.valign-middle,
    td.valign-middle {
        vertical-align: middle;
    }

    table thead th,
    table tfoot th {
        font-weight: $table-header-font-weight;
    }

    tbody tr th {
        display: table-cell;
        line-height: 1.6;
        background: #f7f8f7;
    }

    tbody tr th,
    tbody tr th p,
    tfoot tr th,
    tfoot tr th p {
        color: rgba(0, 0, 0, 0.8);
        font-weight: bold;
    }

    p.tableblock>code:only-child {
        background: none;
        padding: 0;
    }

    p.tableblock {
        font-size: 1em;
    }

    .audioblock,
    .imageblock,
    .literalblock,
    .listingblock,
    .stemblock,
    .videoblock {
        margin-bottom: 1.25em;
    }

    .admonitionblock td.content>.title,
    .audioblock>.title,
    .exampleblock>.title,
    .imageblock>.title,
    .listingblock>.title,
    .literalblock>.title,
    .stemblock>.title,
    .openblock>.title,
    .paragraph>.title,
    .quoteblock>.title,
    table.tableblock>.title,
    .verseblock>.title,
    .videoblock>.title,
    .dlist>.title,
    .olist>.title,
    .ulist>.title,
    .qlist>.title,
    .hdlist>.title {
        text-rendering: optimizeLegibility;
        text-align: center;
        font-style: italic;
        font-size: 1rem;
    }

    .subheader,
    .admonitionblock td.content>.title,
    .audioblock>.title,
    .exampleblock>.title,
    .imageblock>.title,
    .listingblock>.title,
    .literalblock>.title,
    .stemblock>.title,
    .openblock>.title,
    .paragraph>.title,
    .quoteblock>.title,
    table.tableblock>.title,
    .verseblock>.title,
    .videoblock>.title,
    .dlist>.title,
    .olist>.title,
    .ulist>.title,
    .qlist>.title,
    .hdlist>.title {
        line-height: 1.45;
        color: $block-title-color;
        font-weight: $block-title-weight;
        margin-top: .25em;
        margin-bottom: .25em
    }

    span.footnote,
    span.footnoteref {
        vertical-align: super;
        font-size: 0.75em;
    }

    span.footnote a,
    span.footnoteref a {
        text-decoration: none;
    }

    span.footnote a:active,
    span.footnoteref a:active {
        text-decoration: underline;
    }

    .image {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .big {
        font-size: larger;
    }

    .small {
        font-size: smaller;
    }

    .underline {
        text-decoration: underline;
    }

    .overline {
        text-decoration: overline;
    }

    .line-through {
        text-decoration: line-through;
    }

    .aqua {
        color: #00bfbf;
    }

    .aqua-background {
        background-color: #00fafa;
    }

    .black {
        color: black;
    }

    .black-background {
        background-color: black;
    }

    .blue {
        color: #0000bf;
    }

    .blue-background {
        background-color: #0000fa;
    }

    .fuchsia {
        color: #bf00bf;
    }

    .fuchsia-background {
        background-color: #fa00fa;
    }

    .gray {
        color: #606060;
    }

    .gray-background {
        background-color: #7d7d7d;
    }

    .green {
        color: #006000;
    }

    .green-background {
        background-color: #007d00;
    }

    .lime {
        color: #00bf00;
    }

    .lime-background {
        background-color: #00fa00;
    }

    .maroon {
        color: #600000;
    }

    .maroon-background {
        background-color: #7d0000;
    }

    .navy {
        color: #000060;
    }

    .navy-background {
        background-color: #00007d;
    }

    .olive {
        color: #606000;
    }

    .olive-background {
        background-color: #7d7d00;
    }

    .purple {
        color: #600060;
    }

    .purple-background {
        background-color: #7d007d;
    }

    .red {
        color: #bf0000;
    }

    .red-background {
        background-color: #fa0000;
    }

    .silver {
        color: #909090;
    }

    .silver-background {
        background-color: #bcbcbc;
    }

    .teal {
        color: #006060;
    }

    .teal-background {
        background-color: #007d7d;
    }

    .white {
        color: #bfbfbf;
    }

    .white-background {
        background-color: #fafafa;
    }

    .yellow {
        color: #bfbf00;
    }

    .yellow-background {
        background-color: #fafa00;
    }

    span.icon>.fa {
        cursor: default;
    }

    .admonitionblock td.icon [class^="fa icon-"] {
        font-size: 2.5em;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        cursor: default;
    }

    .admonitionblock td.icon .icon-note:before {
        content: "\f05a";
        color: #19407c;
    }

    .admonitionblock td.icon .icon-tip:before {
        content: "\f0eb";
        text-shadow: 1px 1px 2px rgba(155, 155, 0, 0.8);
        color: #111;
    }

    .admonitionblock td.icon .icon-warning:before {
        content: "\f071";
        color: #bf6900;
    }

    .admonitionblock td.icon .icon-caution:before {
        content: "\f06d";
        color: #bf3400;
    }

    .admonitionblock td.icon .icon-important:before {
        content: "\f06a";
        color: #bf0000;
    }

    .admonitionblock>table {
        border-collapse: separate;
        border: 0;
        background: none;
        width: 100%;
    }

    .admonitionblock>table td.icon {
        text-align: center;
        width: 80px;
    }

    .admonitionblock>table td.icon img {
        max-width: none;
    }

    .admonitionblock>table td.icon .title {
        font-weight: bold;
        font-family: "Open Sans", "DejaVu Sans", sans-serif;
        text-transform: uppercase;
    }

    .admonitionblock>table td.content {
        padding-left: 1.125em;
        padding-right: 1.25em;
        border-left: 1px solid #ddddd8;
        color: rgba(0, 0, 0, 0.6);
    }

    .admonitionblock>table td.content> :last-child> :last-child {
        margin-bottom: 0;
    }


    .quoteblock {
        margin: 0 1em 1.25em 1.5em;
        display: table
    }

    .quoteblock>.title {
        margin-left: -1.5em;
        margin-bottom: 0.75em;
    }

    .quoteblock blockquote {
        border-left: 4px solid $grey-color-light;
    }

    .quoteblock blockquote,
    .quoteblock blockquote p {
        color: $blockquote-color;
        padding-left: $spacing-unit / 2;
        font-family: $blockquote-font-family;
        font-weight: $blockquote-font-weight;
        font-style: $blockquote-font-style;
        font-size: $blockquote-font-size;

        > :last-child {
            margin-bottom: 0;
        }
    }

    .quoteblock blockquote>.paragraph:last-child p {
        margin-bottom: 0;
    }

    .quoteblock .attribution {
        margin-top: 0.5em;
        margin-right: 0.5ex;
        text-align: right;
    }

    .quoteblock .quoteblock {
        margin-left: 0;
        margin-right: 0;
        padding: 0.5em 0;
        border-left: 3px solid rgba(0, 0, 0, 0.6);
    }

    .quoteblock .quoteblock blockquote {
        padding: 0 0 0 0.75em;
    }

    .quoteblock .quoteblock blockquote:before {
        display: none;
    }

    .quoteblock .attribution,
    .verseblock .attribution {
        font-size: 0.9375em;
        line-height: 1.45;
        font-style: italic;
    }

    .quoteblock .attribution br,
    .verseblock .attribution br {
        display: none;
    }

    .quoteblock .attribution cite,
    .verseblock .attribution cite {
        display: block;
        color: $blockquote-cite-color;
    }

    /* .quoteblock.abstract { margin: 0 0 1.25em 0; display: block; } */
    /* .quoteblock.abstract blockquote, .quoteblock.abstract blockquote p { text-align: left; word-spacing: 0; } */
    /* .quoteblock.abstract blockquote:before, .quoteblock.abstract blockquote p:first-of-type:before { display: none; } */

    /*
     * Verse blocks
     */
    .verseblock {
        margin: 0 1em 1.25em 1em;
    }

    .verseblock pre {
        font-family: $verseblock-font-family;
        font-size: 1.15rem;
        color: rgba(0, 0, 0, 0.85);
        font-weight: $base-font-weight;
        text-rendering: optimizeLegibility;
    }

    .verseblock pre strong {
        font-weight: $bold-body-font-weight;
    }

    .verseblock .attribution {
        margin-top: 1.25rem;
        margin-left: 0.5ex;
    }


    .literalblock pre,
    .listingblock pre:not(.highlight),
    .listingblock pre[class="highlight"],
    .listingblock pre[class^="highlight "],
    .listingblock pre.CodeRay,
    .listingblock pre.prettyprint {
        background: $block-background-color;
    }

    .listingblock pre.highlightjs {
        padding: 0;
    }

    .listingblock pre.highlightjs>code {
        padding: 1em;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    .listingblock pre.prettyprint {
        border-width: 0;
    }

    .listingblock>.content {
        position: relative;
    }

    .listingblock code[data-lang]:before {
        display: none;
        content: attr(data-lang);
        position: absolute;
        font-size: 0.75em;
        top: 0.425rem;
        right: 0.5rem;
        line-height: 1;
        text-transform: uppercase;
        color: #999;
    }

    .listingblock:hover code[data-lang]:before {
        display: block;
    }

    .listingblock.terminal pre .command:before {
        content: attr(data-prompt);
        padding-right: 0.5em;
        color: #999;
    }

    .listingblock.terminal pre .command:not([data-prompt]):before {
        content: "$";
    }

    /**
     * Code formatting
     */
    code,
    kbd,
    pre,
    samp {
        font-family: $monospace-font-family;
        font-size: $monospace-font-size;
        font-weight: $monospace-font-weight;
        font-style: $monospace-font-style;
    }

    code {
        color: rgba(0, 0, 0, .9);
    }

    *:not(pre)>code {
        font-size: $base-font-size * 0.9;
        font-style: normal !important;
        letter-spacing: 0;
        padding: .1em .5ex;
        word-spacing: -.15em;
        background-color: #f7f7f8;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        line-height: 1.45;
        text-rendering: optimizeSpeed
    }

    pre,
    pre>code {
        font-family: $monospace-font-family;
        font-weight: 400;
        line-height: 1.45;
        color: rgba(0, 0, 0, .9);
        overflow-x: scroll;
        padding: 8px 12px;
        text-rendering: optimizeSpeed;
    }

    pre {
        padding: 8px 12px;

        >code {
            border: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }

    .ulist {
        margin-top: 10px;

        ul {
            margin-left: 40px;
        }

        ul>li>p {
            margin-bottom: 6px;
        }

    }

    .olist {
        margin-top: 10px;

        ol {
            margin-left: 40px;
        }

        ol>li>p {
            margin-bottom: 6px;
        }

    }

    .videoblock {
        width: 100%;
    }
}

.pagination {
    .previous {
        float: left;
        text-size: $small-font-size;
    }

    .next {
        float: right;
        text-size: $small-font-size;
    }

    .disabled {
        color: $grey-color;
    }
}
