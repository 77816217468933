@charset "utf-8";

/* Colors */
$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;
$block-title-color: $text-color;
$block-title-weight: 400;
$blockquote-text-color: rgba(0, 0, 0, 0.85);
$blockquote-cite-color: rgba(0, 0, 0, 0.6);

/* Fonts */
$whitney-font: "Whitney A", "Whitney B", sans-serif;
$chronicle-display: "Chronicle Display A", "Chronicle Display B", serif;
$chronicle-screensmart: "Chronicle SSm A", "Chronicle SSm B", serif;

$whitney-index-font-family: "WhitneyInd B R Lig A", "WhitneyInd B R Lig B";
$whitney-index-font-style: normal;
$whitney-index-font-weight: 400;

$website-title-font-family: serif;
$website-title-font-weight: 400;

$base-font-family: serif;
$base-font-size: 15px;
$base-font-weight: 300;
$base-font-style: normal;
$base-text-color: black;
$base-letter-spacing: 0.03em;
$base-line-height: 1.5;

$bold-body-font-weight: 600;
$bold-body-font-style: normal;

$monospace-font-family: 'Inconsolata', Hack, monospace;
$monospace-font-size: 16px;
$monospace-font-weight: $base-font-weight;
$monospace-font-style: normal;

$headings-font-family: sans-serif;
$headings-font-weight: 400;
$headings-font-style: normal;
$headings-letter-spacing: 0.03em;
$headings-color:   #ba3925; /* This is the asciidoctor default */

$table-header-font-family: serif;
$table-header-font-weight: 700;
$table-header-font-style: normal;

$blockquote-font-family: $base-font-family;
$blockquote-font-size: $base-font-size;
$blockquote-font-weight: $base-font-weight;
$blockquote-font-style: normal;
$blockquote-color:   $grey-color;

$footnote-font-size: 10px;

$verseblock-font-family: $base-font-family;

$small-font-size:  $base-font-size * 0.875;

$spacing-unit:     26px;


$table-font-size: $base-font-size * 0.8;
$table-header-font-size: $table-font-size + 2;

$block-background-color: $background-color; /* #f7f7f8;  */
$table-header-background-color: darken($background-color, 5%); /* #f7f8f7; */

// Width of the content area
$content-width:    700px;

$on-palm:          600px;
$on-laptop:        800px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
	"pygments-xcode"
;
