.listingblock .pygments .hll { background-color: #ffffcc; }
.listingblock .pygments  { background: $block-background-color; }
.listingblock .pygments .tok-c { color: #177500 } /* Comment */
.listingblock .pygments .tok-err { color: #000000 } /* Error */
.listingblock .pygments .tok-k { color: #A90D91 } /* Keyword */
.listingblock .pygments .tok-l { color: #1C01CE } /* Literal */
.listingblock .pygments .tok-n { color: #000000 } /* Name */
.listingblock .pygments .tok-o { color: #000000 } /* Operator */
.listingblock .pygments .tok-cm { color: #177500 } /* Comment.Multiline */
.listingblock .pygments .tok-cp { color: #633820 } /* Comment.Preproc */
.listingblock .pygments .tok-c1 { color: #177500 } /* Comment.Single */
.listingblock .pygments .tok-cs { color: #177500 } /* Comment.Special */
.listingblock .pygments .tok-kc { color: #A90D91 } /* Keyword.Constant */
.listingblock .pygments .tok-kd { color: #A90D91 } /* Keyword.Declaration */
.listingblock .pygments .tok-kn { color: #A90D91 } /* Keyword.Namespace */
.listingblock .pygments .tok-kp { color: #A90D91 } /* Keyword.Pseudo */
.listingblock .pygments .tok-kr { color: #A90D91 } /* Keyword.Reserved */
.listingblock .pygments .tok-kt { color: #A90D91 } /* Keyword.Type */
.listingblock .pygments .tok-ld { color: #1C01CE } /* Literal.Date */
.listingblock .pygments .tok-m { color: #1C01CE } /* Literal.Number */
.listingblock .pygments .tok-s { color: #C41A16 } /* Literal.String */
.listingblock .pygments .tok-na { color: #836C28 } /* Name.Attribute */
.listingblock .pygments .tok-nb { color: #A90D91 } /* Name.Builtin */
.listingblock .pygments .tok-nc { color: #3F6E75 } /* Name.Class */
.listingblock .pygments .tok-no { color: #000000 } /* Name.Constant */
.listingblock .pygments .tok-nd { color: #000000 } /* Name.Decorator */
.listingblock .pygments .tok-ni { color: #000000 } /* Name.Entity */
.listingblock .pygments .tok-ne { color: #000000 } /* Name.Exception */
.listingblock .pygments .tok-nf { color: #000000 } /* Name.Function */
.listingblock .pygments .tok-nl { color: #000000 } /* Name.Label */
.listingblock .pygments .tok-nn { color: #000000 } /* Name.Namespace */
.listingblock .pygments .tok-nx { color: #000000 } /* Name.Other */
.listingblock .pygments .tok-py { color: #000000 } /* Name.Property */
.listingblock .pygments .tok-nt { color: #000000 } /* Name.Tag */
.listingblock .pygments .tok-nv { color: #000000 } /* Name.Variable */
.listingblock .pygments .tok-ow { color: #000000 } /* Operator.Word */
.listingblock .pygments .tok-mb { color: #1C01CE } /* Literal.Number.Bin */
.listingblock .pygments .tok-mf { color: #1C01CE } /* Literal.Number.Float */
.listingblock .pygments .tok-mh { color: #1C01CE } /* Literal.Number.Hex */
.listingblock .pygments .tok-mi { color: #1C01CE } /* Literal.Number.Integer */
.listingblock .pygments .tok-mo { color: #1C01CE } /* Literal.Number.Oct */
.listingblock .pygments .tok-sb { color: #C41A16 } /* Literal.String.Backtick */
.listingblock .pygments .tok-sc { color: #2300CE } /* Literal.String.Char */
.listingblock .pygments .tok-sd { color: #C41A16 } /* Literal.String.Doc */
.listingblock .pygments .tok-s2 { color: #C41A16 } /* Literal.String.Double */
.listingblock .pygments .tok-se { color: #C41A16 } /* Literal.String.Escape */
.listingblock .pygments .tok-sh { color: #C41A16 } /* Literal.String.Heredoc */
.listingblock .pygments .tok-si { color: #C41A16 } /* Literal.String.Interpol */
.listingblock .pygments .tok-sx { color: #C41A16 } /* Literal.String.Other */
.listingblock .pygments .tok-sr { color: #C41A16 } /* Literal.String.Regex */
.listingblock .pygments .tok-s1 { color: #C41A16 } /* Literal.String.Single */
.listingblock .pygments .tok-ss { color: #C41A16 } /* Literal.String.Symbol */
.listingblock .pygments .tok-bp { color: #5B269A } /* Name.Builtin.Pseudo */
.listingblock .pygments .tok-vc { color: #000000 } /* Name.Variable.Class */
.listingblock .pygments .tok-vg { color: #000000 } /* Name.Variable.Global */
.listingblock .pygments .tok-vi { color: #000000 } /* Name.Variable.Instance */
.listingblock .pygments .tok-il { color: #1C01CE } /* Literal.Number.Integer.Long */